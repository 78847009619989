import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Remote work, design, & a pandemic`}</h1>
    <p>{`You don’t need to be an expert to know that our work and learning will be fundementally changed well after this pandemic has passed.`}</p>
    <p>{`Of course many of these changes are uncomfortable and less than ideal but some of them will be positive.`}</p>
    <p>{`For one, we’re experiencing a mass introduction of remote work, learning, and tools to support them.`}</p>
    <p>{`A good chunk of marketing & branding is spent trying motivate people to try something new for the first time.`}</p>
    <p>{`This pandemic is forcing change that would take a decade of branding and marketing to accomplish.`}</p>
    <p>{`However, that experience is “forced” and being experienced a state of heightened anxiety`}</p>
    <p>{`There’s enough focus on the negative effects, we’re all aware of them, I’m not an expert on them and it helps to focus on the positive sometimes with a sober awareness that we’re all going through a tough time.`}</p>
    <p>{`So what are the positive by products of a pandemic.`}</p>
    <p>{`It’s important to stay positive, keep calm and carry on, and have empathy for those that are struggling`}</p>
    <p>{`There are some assumptions I think are highly probable`}</p>
    <ul>
      <li parentName="ul">{`Remote work will be normal`}</li>
      <li parentName="ul">{`Remote education will be normal`}</li>
    </ul>
    <p>{`Many other things will be normalized:`}</p>
    <ul>
      <li parentName="ul">{`Grocery and restaurant goods delivery: The “gig economy” won’t be so much of a gig anymore.`}</li>
      <li parentName="ul">{`Movie theatres will release more (if not most) to streaming at the same time as theatrical releases`}</li>
      <li parentName="ul">{`Increased automation in manufacturing:`}</li>
    </ul>
    <p>{`Half of marketing is getting people to even try something, that barrier is being removed
However, that experience is “forced” and being experienced a state of heightened anxiety
It’s important to stay positive, keep calm and carry on, and have empathy for those that are struggling
Automation in manufacturing
3d printing of ventilators etc.
On-demand goods
Enhanced virtual community tools
What we have right now is pretty primitive when it comes to communal experiences. Church, events, parties, etc.
Look for the helpers: `}<a parentName="p" {...{
        "href": "https://img.huffingtonpost.com/asset/5b196c9f1a0000c704ce0e1d.jpeg?ops=scalefit_720_noupscale"
      }}>{`https://img.huffingtonpost.com/asset/5b196c9f1a0000c704ce0e1d.jpeg?ops=scalefit_720_noupscale`}</a>{`
Help the helpers
Be the helper
How can designers help?
Posters/media/helpful “propaganda” to encourage social distancing
Design apps for symptom diagnosis, contact tracing etc.
Design services and tools for helping people in need, groceries, mental health, etc.
Likely lots more
So this is really a talk about remote work
Remote work can be amazing and beneficial at any time
We work on the internet which, under normal circumstances, allows us to have a lot of mobility
Spend the month of March with my family in England
Learn new tools: There are some great collaborative tools that are valuable to learn (Figma, Slack, etc.)
There are also some very real challenges to remote work even in normal circumstances
`}<strong parentName="p">{`Collaboration`}</strong>{` is more difficult though not impossible. Introversion a bit of a super power
`}<strong parentName="p">{`Motivation`}</strong>{` and productivity can be hard
Helps if you’re introverted
Especially during anxious times
`}<strong parentName="p">{`Connection`}</strong>{` is difficult. We all need purpose and meaning in our work and much of that comes through the social connections we make at school and work
So let’s talk about some ways we, as designers can combat the issues above while we work
`}<strong parentName="p">{`Collaboration`}</strong>{`
Zoom calls
`}<a parentName="p" {...{
        "href": "https://youtu.be/Jmw00iPlPKI?t=1050"
      }}>{`https://youtu.be/Jmw00iPlPKI?t=1050`}</a>{`
Figma
`}<a parentName="p" {...{
        "href": "https://youtu.be/Zj163lgt_kI?t=250"
      }}>{`https://youtu.be/Zj163lgt_kI?t=250`}</a>{`
Slack
Check-ins
Every day in the morning we answer the question “What are you working on today?”
Coffee walks
In the afternoon, we do a remote call where we drink coffee and walk around wherever we are and just chat
Documentation, communication etc.
Basecamp
`}<a parentName="p" {...{
        "href": "https://3.basecamp.com/3091871/projects"
      }}>{`https://3.basecamp.com/3091871/projects`}</a>{`
Notion
`}<a parentName="p" {...{
        "href": "https://www.notion.so/pathwright/bdbcca03feb248dcbd032caf4d06152d?v=ca24348eef2744beb359fba144d3f771"
      }}>{`https://www.notion.so/pathwright/bdbcca03feb248dcbd032caf4d06152d?v=ca24348eef2744beb359fba144d3f771`}</a>{`
`}<strong parentName="p">{`Motivation`}</strong>{`
Design your environement
You’re preferred lighting
Noise canceling headphones if necessary
Comfortable chair
Work in the same place every day, ideally separate that place from entertainment, even moving your chair/laptop will help your brain associate your “place” with work
Uniforms/loadouts (`}<a parentName="p" {...{
        "href": "https://i.redd.it/bzkd3t74wxj21.jpg"
      }}>{`https://i.redd.it/bzkd3t74wxj21.jpg`}</a>{`)
We don’t have to wear pants anymore?!
A uniform can help us get in the right mode to work
“Loadouts”
Work Mark
Exercise Mark
Chill Mark
etc.
The Pomodoro Technique
25 min blocks, 5 min breaks (stand up!)
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?time_continue=13&v=VFW3Ld7JO0w"
      }}>{`https://www.youtube.com/watch?time_continue=13&v=VFW3Ld7JO0w`}</a>{`
Mental & physical health
Get in nature if you can, walk.
Calm app, reading, etc.
Don’t just binge watch Tiger King
`}<strong parentName="p">{`Connection`}</strong>{`
We need the water cooler talk
Should not be all about work
Coffee/lunch hangouts via Zoom/Facetime/house party
House Party: `}<a parentName="p" {...{
        "href": "https://houseparty.com/"
      }}>{`https://houseparty.com/`}</a>{`
bought by “epic games” (fortnight)
Hang out in a game (fortnight etc.)
Remote podcast, book clubs
Conclusion
So those are just a few ideas for how we can work together remotely. There are many other resources
Of course, your productivity is not the most important thing right now. Stay healthy, live well.
We are at a turning point in the world, we’re all going through the same thing together.
This is an unprecedented time in history and we need design now more than ever
Be the helper. Design something that matters and do it with the people you care about.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      